<template>
<v-card :class="{ float: setFloat, vertical: vertical, 'elevation-0' : flat }" class="meo-progress-tracker">
    <p class="text-h5 my-2 font-weight-bold" :class="{ 'd-none': hideTitle }">
        Progress
    </p>
    <div class="progress">
        <div class="progress-icon" :class="{ shrink: index == items.length - 1 }" v-for="(item, index) in items" :key="index">
            <template v-if="(item.data && item.data.image) || item.image">
                <div class="image" :style="backgroundImage(item)"></div>
            </template>
            <template v-if="item.data && item.data.icon">
                <div v-if="!hasCompleted(item)" class="image">
                    <v-icon @click.stop="goTo(item)" color="dark" size="32">{{ item.data.icon }}</v-icon>
                </div>
                <div v-else class="image">
                    <!-- <v-icon @click.stop="goTo(item)" color="yellow" size="32">mdi-star</v-icon> -->
                    <v-icon @click.stop="goTo(item)" color="yellow" size="32">{{ item.data.icon }}</v-icon>
                </div>
            </template>
            <div :class="[hasCompleted(item) ? 'solid' : 'dash']" v-if="index != items.length - 1"></div>

        </div>
    </div>
</v-card>
</template>
<script>
// Services
import Module from "@/modules/meo-module/module";
export default {
    name: "meo-progress-tracker",
    props: {
        items: {
            type: Array,
        },
        completionArray: {
            type: Array,
        },
        setFloat: {
            type: Boolean,
        },
        hideTitle: {
            type: Boolean,
        },
        vertical: {
            type: Boolean,
        },
        screens: {
            type: Array,
            default () {
                return [];
            },
        },
        flat: {
            type: Boolean
        }
    },
    methods: {
        backgroundImage(item) {
            if ((item.data && item.data.image) || item.image) {
                return {
                    backgroundImage: `url(${this.$fluro.asset.imageUrl(item.data.image || item.image)})`,
                };
            }
            return {};
        },
        hasCompleted(item) {
            // check to see if there are any items in the screens array if the user has not completed any screens then set screens ot empty array
            let screens = this.screens || [];
            if (screens.length) {
                // create an array of all the ids available in the screens array (all screens that are completed)
                let ids = screens.map(({ _id }) => _id);
                // try to find a match in the array of completed section ids for the current sections id

                let match = ids.includes(item._id);
                if (match) {
                    // if a match is found return true so the star icon will display
                    return true;
                }
            }
            return false;
        },
        goTo(item) {
            const path = `/sessions/session/${this.$route.params.id}/screen/${item._id}`;

            if (this.$route.path !== path) {
                this.$emit("next", item);
                // this.$router.push({ name: "module.screen", params: { screenId: item._id } });
            }
        },
    },
    mounted() {

    },
};
</script>
<style lang="scss">
.meo-progress-tracker {
    background: #ffffff;
    border-radius: 10px !important;
    padding: 10px;
    position: relative;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .progress {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .progress-icon {
            display: flex;
            align-items: center;
            width: 100%;
            &.shrink {
                flex-shrink: 1;
                flex-grow: 0;
                width: auto;
            }
            .image {
                margin: 0 10px;
            }
            .dash {
                width: 100%;
                height: 2px;
                background-image: linear-gradient(to right, var(--v-dark-base) 33%, rgba(255, 255, 255, 0) 0%);
                background-position: bottom;
                background-size: 10px 2px;
                background-repeat: repeat-x;
            }
            .solid {
                width: 100%;
                height: 2px;
                background-color: black;
                background-position: bottom;
                background-size: 10px 2px;
                background-repeat: repeat-x;
            }
        }
    }
    &.vertical {
        width: 80px;
        .progress {
            flex-direction: column;
            .progress-icon {
                flex-direction: column;
                .dash {
                    width: 2px;
                    height: 100px;
                    background-image: linear-gradient(var(--v-dark-base) 33%, rgba(255, 255, 255, 0) 0%);
                    background-position: right;
                    background-size: 2px 10px;
                    background-repeat: repeat-y;
                }
                .solid {
                    width: 2px;
                    height: 100px;
                    background-color: black;
                    background-position: right;
                    background-size: 2px 10px;
                    background-repeat: repeat-y;
                }
            }
        }
    }
}
</style>
