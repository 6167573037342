<template>
<v-container>
    <v-row justify="center" class="p-relative zindex-1">
        <v-col cols="12" md="4" lg="3" v-for="item in sections" :key="item._id">
            <template v-if="$vuetify.breakpoint.smAndDown">
                <meo-list-item :item="item" section @click.native="goTo(item)"></meo-list-item>
            </template>

            <template v-else>
                <meo-card-item :oldContact="contact" :item="item" section @click.native="goTo(item)"></meo-card-item>
            </template>
        </v-col>

        <v-col cols="12" lg="9">
            <meo-progress-tracker :items="sections" :screens="screens" @next="goTo"></meo-progress-tracker>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
// Mixins
import UserMixin from "@/mixins/user-mixin";
import SEOMixin from "@/mixins/seo-mixin";
// Components
import MeoListItem from "@/components/misc/meo-list-item.vue";
import MeoCardItem from "@/components/misc/meo-card-item.vue";
import MeoProgressTracker from "@/components/misc/meo-progress-tracker.vue";

export default {
    name: "module-menu",

    meta() {
        return { titleTemplate: `${this.module.title} | %s` };
    },


    props: {
        contact: {
            type: Object,
        },
        sections: {
            type: Array,
        },
        module: {
            type: Object,
        },
        screens: {
            type: Array,
            default () {
                return [];
            },
        },
    },

    data() {
        return {
            title: "",
        }

    },

    components: {
        MeoListItem,
        MeoProgressTracker,
        MeoCardItem
    },

    computed: {
        moduleTitle() {
            if (this.module) {
                return this.module.title;

            } else {
                return "i am text"
            }
        }
    },

    methods: {
        goTo(item) {
            this.$router.push({
                name: "module.screen",
                params: { id: this.$route.params.id, screenId: item._id },
            });
        },
    },

    created() {
        this.title = this.module.title;
    }
};
</script>
<style lang="scss">

</style>
